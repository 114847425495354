<template>
  <div>
    aaasas5161
    <div class="gcse-search"></div>
  </div>
</template>

<script>
export default {
  name: "GoogleCSE",

  data() {
    return {
      scriptLoaded: false,
    };
  },

  mounted() {
    this.loadScript();
  },

  methods: {
    loadScript() {
      // Check if the script is already loaded
      if (this.scriptLoaded) return;

      const script = document.createElement("script");
      script.async = true;
      script.src = "https://cse.google.com/cse.js?cx=c050d2300a3954d1e";

      // Attach an event listener to the script tag to check when it's loaded
      script.onload = () => {
        this.scriptLoaded = true;
        this.onScriptLoad();
      };

      // Error handling
      script.onerror = (error) => {
        console.error("Error loading the Google CSE script", error);
      };

      // Append the script to the body
      document.body.appendChild(script);
    },

    onScriptLoad() {
      // Logic to be executed after the script is loaded can be placed here
      console.log("Google CSE script loaded!");
    },
  },
};
</script>
