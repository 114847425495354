<template>
  <b-card class="result-card">
    <div class="card-header">
      <div class="header-content">
        <img class="avatar" :src="avatar" :alt="title" />
        <div class="header-text">
          <a class="result-url" :href="url">{{ url }}</a>
          <h2 class="result-title">{{ title }}</h2>
        </div>
      </div>
      <b-dropdown variant="link" size="is-small">
        <template slot="trigger">
          <button class="dot-menu-button">
            <b-icon icon="dots-vertical"></b-icon>
          </button>
        </template>
        <b-dropdown-item>Option 1</b-dropdown-item>
        <b-dropdown-item>Option 2</b-dropdown-item>
      </b-dropdown>
    </div>
    <p class="result-description">{{ description }}</p>
  </b-card>
</template>

<script>
export default {
  props: {
    avatar: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.result-card {
  margin-bottom: 20px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content {
  display: flex;
  align-items: center;
}

.avatar {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.header-text {
  display: flex;
  flex-direction: column;
}

.result-url {
  color: green;
  margin-bottom: 0.25em;
}

.result-title {
  font-size: 1.25em;
  margin-bottom: 0.25em;
}

.dot-menu-button {
  border: none;
  background-color: transparent;
}

.result-description {
  margin-top: 0.5em;
}
</style>
