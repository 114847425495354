<template>
  <div v-if="items && items.length">
    <div v-for="item in items" :key="item.cacheId" class="result-item">
      <div class="top-row">
        <div class="icon"></div>
        <div class="site-info">
          <div class="site-name">{{ getDomainName(item.displayLink) }}</div>
          <div class="site-url">{{ item.formattedUrl }}</div>
        </div>
        <div class="ellipsis-icon">⋮</div>
        <img
          v-if="item.pagemap && item.pagemap.cse_image && item.pagemap.cse_image[0].src"
          :src="item.pagemap.cse_image[0].src"
          class="result-image"
          alt="Search Result Image"
        />
      </div>
      <h3 class="title">
        <a :href="item.link" target="_blank">{{ item.title }}</a>
      </h3>
      <div class="snippet" v-html="item.htmlSnippet"></div>
    </div>
    {{ items }}
  </div>
</template>

<script>
export default {
  name: "GoogleSearchResults",
  props: {
    items: {
      type: Array,
      required: false,
    },
  },
  methods: {
    getDomainName(url) {
      return String(url).replace("www.", "");
      return url;
      return new URL(url).hostname;
    },
  },
};
</script>

<style scoped>
.result-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.top-row {
  display: flex;
  align-items: center;
}

.icon {
  width: 16px;
  height: 16px;
  background-color: grey; /* Replace with a suitable icon */
  margin-right: 5px;
}

.site-info {
  flex-grow: 1;
}

.site-name {
  font-size: 14px;
  font-weight: bold;
}

.site-url {
  font-size: 12px;
  color: #006621;
}

.ellipsis-icon {
  margin-left: 10px;
  font-size: 20px;
  cursor: pointer;
}

.title {
  margin-top: 10px;
  color: #1a0dab;
  font-size: 18px;
}

.snippet {
  margin-top: 5px;
  font-size: 14px;
}

.result-image {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  object-fit: cover;
}
</style>
