<template>
  <section>
    <googleSearchResults :items="googleSearchResults.items" />
    {{ googleSearchResults }}6565656555
    <div class="spacerNonSticky" style="height: 30px"></div>
    <div class="search-header">
      <div class="container w800">
        <searchInput v-model="query" @search="formSubmit" />

        <!--  
        <form @submit.prevent="formSubmit">
          <b-field label=" ">
            <div class="the-input">
              <b-input
                v-model="query"
                @keyup.enter="formSubmit"
                type="search"
                size="is-large"
                rounded
                placeholder="Search for anything"
                icon="search"
                icon-pack="fal"
                icon-right="search"
                icon-right-clickable
                @icon-right-click="formSubmit"
              ></b-input>
            </div>
          </b-field>

 
        </form>
      -->
        <!-- 
        <b-field label="Find a name">
          <b-autocomplete
            v-model="name"
            placeholder="e.g. Anne"
            :keep-first="false"
            :open-on-focus="true"
            :data="autocompleteSuggestions"
            field="label"
            @select="(option) => (selected = option)"
            :clearable="false"
          >
          </b-autocomplete>
        </b-field>

        
        <b-field label="Find a movie">
          <b-autocomplete
            :data="data"
            placeholder="e.g. Fight Club"
            field="title"
            :loading="isFetching"
            @typing="getAsyncData"
            @select="(option) => (selected = option)"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-left">
                  <img width="32" :src="`https://image.tmdb.org/t/p/w500/${props.option.poster_path}`" />
                </div>
                <div class="media-content">
                  {{ props.option.title }}
                  <br />
                  <small>
                    Released at {{ props.option.release_date }}, rated <b>{{ props.option.vote_average }}</b>
                  </small>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
      
       :type="b.active ? 'is-info is-active  is-light' : 'regular'"


        {{ buttonsShownThatFits }} ==buttonsShownThatFits
              -->

        <div class="product-buttons" ref="buttonsContainer" v-show="!loadingButtons">
          <b-button
            v-for="b in buttonsShown"
            :key="b.id"
            :is-active="true"
            @click="clickButton({ id: b.id, q: b.q, v: b.id })"
            rounded
            :icon-left="b.label == 'All' ? 'chevron-left' : ''"
            icon-pack="fal"
            :class="{
              regular: !b.active,
              'is-info is-active  is-light': b.active,
              borderless: b.label == 'All',
            }"
            style="text-decoration: none"
          >
            {{ b.label }}
          </b-button>
        </div>
      </div>
    </div>

    <!-- 
    {{ buttonsShown }}++++++ {{ loadingButtons }}loadingButtons
    {{ vertical }}
    {{ relatedQueries }}

    ====


     -->

    <div v-if="vertical == 'bots'">
      <br />
      <br />

      <div class="container w1200">
        <div class="columns is-variable">
          <div class="column" style="padding-right: 30px">
            <BotList :bots="bots" :noDescription="false" :pageSize="9999" />
          </div>
          <div class="column is-narrow is-hidden-touch">
            <div class="sticky" style="max-width: 300px">
              <img
                src="https://cdn1.onlybots.cc/path/to/upload/folder0_ON-XKQL3___search2.png"
                style="height: 150px; margin: auto; display: block; max-width: 80%; margin-bottom: 30px"
              />
              <div class="cat-buttons" style="margin-bottom: 45px">
                <router-link
                  :to="cat.to"
                  replace
                  class="button is-primary"
                  :class="{ 'is-light': !cat.active }"
                  v-for="cat in botCategories"
                  :key="cat.id"
                >
                  <!-- icon ---->
                  <span class="le"
                    ><span class="icon is-small">
                      <i :class="'icon far fa-' + cat.icon"></i>
                    </span>
                    <span> {{ cat.label }}</span></span
                  >
                  <span class="count">{{ cat.count }} </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="vertical == 'imagine'">
      <br />
      <br />
      <div class="container w1200">
        <div class="columns is-variable">
          <div class="column" style="padding-right: 30px">
            <searchImagine :fullview="true" :templates="bots" :noDescription="false" :pageSize="9999" />
          </div>
        </div>
      </div>
    </div>

    <!--     
    PARENT:
    <br />
    {{ initialSelectedImage }} == initialSelectedImage

    <hr /> -->

    <div v-if="vertical == 'images'">
      <imageSearchResults
        :images="images"
        :initialSelectedImage="initialSelectedImage"
        :initialTrayImageSequence="initialTrayImageSequence"
        @clickImageMosaic="clickImageMosaic"
        @clickImageTray="clickImage"
        @nextImage="nextImage"
        :isSearchResults="true"
        ref="imageSearchResults"
      />
      <!-- 
        
         @setSelectedImage="setSelectedImage"
         
         22222 OLDDDD
      <div class="columns is-variable is-0">
        <div class="column" style="padding-right: 30px">
          <searchImageMosaic
            :scale="190"
            trayImageSequence="trayImageSequence"
            :activeImageId="selectedImage?.id"
            :images="images"
            @clickImage="clickImageMosaic"
            :pageSize="200"
            style="width: 100%"
          />
        </div>
        <div class="column is-4" v-if="selectedImage" style="min-width: 480px; max-width: 780px">
          <div class="img-tray-wrap">
            <searchImageDetailTray
              :item="selectedImage"
              @clickImage="clickImage"
              @close="selectedImage = null"
              :disablePrev="trayCurrentIndex == 0"
              :disableNext="false"
              @next="nextImage(1)"
              @prev="nextImage(-1)"
            />
          </div>
        </div>
      </div>
      -->
    </div>

    <div v-if="vertical == 'posts'">
      <postList :items="images" :pageSize="200" style="max-width: 550px" />
    </div>

    <div v-if="vertical == 'all'">
      <div class="results" style="position: relative; min-height: 300px">
        <LoadingSpinner v-if="isLoadingAll" />
        <!-- 
        <b-loading :is-full-page="false" v-model="isLoadingAll" :can-cancel="true"></b-loading> -->
        <div v-if="!isLoadingAll" class="results-container container w800" style="max-width: 650px">
          <span class="title is-6" style="opacity: 0.5; font-size: 14px; font-weight: 400" v-if="allSearchResults?.ms">
            <!--About 2,140,000,000 results ({{ (allSearchResults?.ms / 1000).toFixed(2) }} 0.34 seconds)  
            
              Bots and AI 
            -->
            Infinite ad-free results ({{ (allSearchResults?.ms / 1000).toFixed(2) }} seconds)
          </span>
          <!-- block loop here -->
          <div v-if="allSearchResults && allSearchResults.blocks">
            <div v-for="i in allSearchResults.blocks" :key="i.feature + 441 + 'allSearchResultsloop'">
              <json-debug v-if="debug" :data="i.data" :label="i.feature" :key="i.feature + 215" />
              <searchResultBlock
                @viewAllImages="clickButton({ id: 'images', q: query, v: 'images' })"
                @viewAllBots="clickButton({ id: 'bots', q: query, v: 'bots' })"
                @viewAllImagine="clickButton({ id: 'imagine', q: query, v: 'imagine' })"
                @clickImageInTeaser="clickImageInTeaser"
                :id="i.feature"
                :data="i.data"
                :key="i.feature + 841"
              ></searchResultBlock>
            </div>
          </div>

          <json-debug v-if="debug" :data="allSearchResults" label="allSearchResults"></json-debug>
          <hr />
          <hr />
          <hr />
          <!--
          <div class="imgTeaser" style="width: 100%; max-width: 800px">
           img preview teaser
            <searchImageTeaser
              :images="images.slice(0, 33)"
              @viewAllImages="clickButton({ id: 'images', q: query, v: 'images' })"
              @clickImageInTeaser="clickImageInTeaser"
              :scale="70"
              style="width: 100%"
            />
          </div>
          <searchFaq :faqs="faqs" v-if="!loadingFaqs && faqs.length" />
-->
          <SearchResultCard
            v-for="result in results"
            :key="result.id"
            :avatar="result.avatar"
            :url="result.url"
            :title="result.title"
            :description="result.description"
          />
        </div>
      </div>
      <b-button v-if="$store.main.getters.isAdmin" @click="debug = !debug"> DEBUG view data</b-button>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import SearchResultCard from "@/components/SearchResultCard.vue";
import LoadingSpinner from "@/components/e/LoadingSpinner.vue";
//import searchFaq from "@/components/search/searchFaq.vue";

import postList from "@/components/PostList.vue";

import googleSearchEmbed from "@/components/embeds/googleSearchEmbed.vue";
import googleSearchResults from "@/components/search/googleSearchResults.vue";

import searchResultBlock from "@/components/search/searchResultBlock.vue";
import searchImageMosaic from "@/components/search/searchImageMosaic.vue";
import imageSearchResults from "@/components/search/imageSearchResults.vue";
//import searchImageTeaser from "@/components/search/searchImageTeaser.vue";

import searchImageDetailTray from "@/components/search/searchImageDetailTray.vue";
import searchImagine from "@/components/search/searchImagine.vue";

import searchInput from "@/components/search/searchInput.vue";
import JsonDebug from "../components/e/jsonDebug.vue";

import { SEARCH_VERTICALS } from "@/data/searchVerticals.js";

const PRODUCT_BUTTONS = SEARCH_VERTICALS;

const FAKE_RESULTS = [
  {
    id: 1,
    avatar: "https://api.onlybots.cc/a/Frida",
    url: "Frida",
    title: "Result 1",
    description: "This is a description of the first search result.",
  },
  {
    id: 2,
    avatar: "https://api.onlybots.cc/a/Frida",
    url: "Bot #2",
    title: "Result 2",
    description: "This is a description of the second search result.",
  },
  {
    id: 3,
    avatar: "https://api.onlybots.cc/a/Frida",
    url: "Bot #3",
    title: "Result 3",
    description: "This is a description of the third search result.",
  },
];

const FAKE_FAQ = [
  {
    q: "What are some interesting facts about cats?",
    longResponse:
      "Cats have a unique ability to rotate their ears 180 degrees. They also have a specialized collarbone that allows them to always land on their feet when they fall. Additionally, cats have a keen sense of hearing and can detect frequencies as high as 64,000 Hz.",
    shortResponse: "Rotating ears, landing on feet, keen hearing",
  },
  {
    q: "How long do cats live on average?",
    longResponse:
      "The average lifespan of a domestic cat is around 15 years. However, with proper care and nutrition, cats can live well into their 20s or even 30s.",
    shortResponse: "Around 15 years, but can live longer",
  },
  {
    q: "What are some common cat breeds?",
    longResponse:
      "Some common cat breeds include the Persian, Siamese, Maine Coon, Bengal, and Ragdoll. Each breed has its own unique characteristics and appearance.",
    shortResponse: "Persian, Siamese, Maine Coon, Bengal, Ragdoll",
  },
  {
    q: "Do cats always land on their feet?",
    longResponse:
      "Cats have a natural ability to twist their bodies mid-air and land on their feet. However, this doesn't mean they are immune to injuries from falls. It's important to keep windows and balconies secured to prevent accidents.",
    shortResponse: "Yes, but can still get injured",
  },
  {
    q: "What should I feed my cat?",
    longResponse:
      "Cats are obligate carnivores, which means they require a diet primarily consisting of meat. It's best to feed them high-quality cat food that is specifically formulated to meet their nutritional needs.",
    shortResponse: "High-quality cat food",
  },
  {
    q: "How do I introduce a new cat to my existing cat?",
    longResponse:
      "Introducing a new cat to your existing cat should be done gradually. Start by keeping them in separate rooms and gradually allow them to interact under supervision. Provide each cat with their own resources, such as food bowls and litter boxes.",
    shortResponse: "Gradual introduction, separate rooms",
  },
];

import botCategoriesData from "@/data/categories.js";

const FAKE_RELATED_QUERIES = [
  { label: "Delivery", q: "Pizza delivery near me" },
  { label: "Types", q: "Different types of pizza" },
  { label: "Recipes", q: "Homemade pizza recipes" },
  { label: "Toppings", q: "Popular pizza toppings" },
  { label: "History", q: "History of pizza" },
];

const DEBUG = false;

export default {
  components: {
    SearchResultCard,
    // searchFaq,
    searchInput,
    postList,
    //  searchImageMosaic,
    //searchImageTeaser,
    //searchImageDetailTray,
    searchResultBlock,
    LoadingSpinner,
    searchImagine,
    imageSearchResults,
    // googleSearchEmbed,
    googleSearchResults,
  },
  data() {
    JsonDebug;
    return {
      //botCategories: botCategories,
      isLoading: false,
      // autocompleteSuggestions: [],
      query: "",
      // buttons: PRODUCT_BUTTONS,
      allSearchResults: {}, //fat object with all sort of blocks
      results: [], // initially empty, will be populated with search results
      faqs: [],
      images: [],
      selectedImage: null,
      relatedQueries: [],
      loadingFaqs: true, //true,
      loadingRelated: true,
      loadingRelatedQueries: true,
      isLoadingAll: true,

      debug: false,
      bots: [],

      initialTrayImageSequence: null,
      initialSelectedImage: null,
      googleSearchResults: [],
    };
  },
  methods: {
    /*
    getAutocomplete(s) {
      window.API.getSearchAutocomplete({ search: s }).then((ar) => {
        this.autocompleteSuggestions = ar;
        // this.checkIfAllCallsAreCompleted();
        // console.log("PROJJ!", proj, proj.id);
      });
    },*/
    async formSubmit() {
      //  alert("formSubmit");
      //this.performSearch();
      // update current route with params from form
      // alert(this.query);
      //only replace if different
      // if (this.$route.query.q != this.query)

      var q = String(this.query).trim();
      if (this.$route.query.q != q) {
        //just prevents redundant nav
        this.$router.push({ path: "/search", query: { q: q, v: this.vertical } });
      }
    },
    async performSearch() {
      // TODO: ensure that big long calls are flushed when new ones are made. as ther,s disruptencies between repsonse time.

      //call the search
      this.isLoading = true;
      this.isLoadingAll = true;
      this.loadingFaqs = true;
      this.loadingRelatedQueries = true;
      this.loadingAllBlocks = true;
      this.relatedKeywords = [];
      this.faqs = [];
      this.relatedQueries = [];
      this.results = [];

      var q = String(this.query).trim();

      q = q.trim();
      if (q == "") {
        this.isLoading = false;
        return;
      }

      /*  if (DEBUG) {
        // perform your search here, then update results and isLoading accordingly
        // the following is a placeholder and should be replaced with your own search code

        setTimeout(() => {
          this.results = FAKE_RESULTS;
          this.faqs = FAKE_FAQ;
          this.relatedQueries = FAKE_RELATED_QUERIES;
          this.loadingFaqs = false;
          this.loadingRelatedQueries = false;

          //  alert(q);
          this.isLoading = false;
        }, 1000);

        return;
      }*/

      window.API.getGoogleSearch({
        search: q,
      }).then((myData) => {
        this.googleSearchResults = myData;
        // code to display myData
      });

      const v = this.vertical;
      if (v == "images") {
        //this.images = []; // dont reset - it could have been provision by main search...
        // if ((this.initialSelectedImage && this.initialSelectedImage.id != this.$route.query.i) || true) {
        var selectedInChild = this.$refs.imageSearchResults?.selectedImage;
        var imageId = this.$route.query.i;
        console.log(7897987897897, selectedInChild);
        if (selectedInChild?.id != imageId) {
          this.initialSelectedImage = null;
          this.$refs.imageSearchResults.selectedImage = null;
          //also, clear any state in the component...
        }

        // this.initialSelectedImage = null;
        //this.$refs.imageSearchResults.selectedImage = null;

        // this.initialSelectedImage = null;

        //    this.initialTrayImageSequence = null; //no need to cancel tray uppon big re-search, it will re-popipar uppon open, need to be there upon teaser click
        window.API.searchImages({
          search: q,
        }).then((myData) => {
          this.images = myData;
          // code to display myData
        });
        /*
      window.API.getSearchFaq({ search: q }).then((txtttt) => {
        this.results = FAKE_RESULTS;
        this.faqs = txtttt;
        this.loadingFaqs = false;
        this.isLoading = false; //mehhh
        // this.checkIfAllCallsAreCompleted();
        // console.log("PROJJ!", proj, proj.id);
      });
      */
      }

      if (v == "bots") {
        //this.images = []; // dont reset - it could have been provision by main search...
        var category = this.$route.query.cat;
        var para = {
          search: q,
        };
        if (category) para.category = category;
        window.API.searchBotsAdvanced(para).then((myData) => {
          this.bots = myData;
          // console.log(this.bots);
          // code to display myData
        });
      }

      if (v == "all") {
        window.API.getRelatedSearchQueries({ search: q }).then((txtttt) => {
          this.relatedQueries = txtttt;
          this.loadingRelatedQueries = false;
          // this.checkIfAllCallsAreCompleted();
          // console.log("PROJJ!", proj, proj.id);
        });

        this.allSearchResults = {};
        window.API.searchAll({ search: q }).then((res) => {
          this.allSearchResults = res;
          this.loadingAllBlocks = false;
          this.isLoadingAll = false;

          this.allSearchResults?.blocks.forEach((b, i) => {
            console.log(f);
            console.log(f, b.data);
            var f = b.feature;
            if (f == "faq") {
              this.faqs = b.data;
            }
            if (f == "related") {
              this.relatedQueries = b.data;
            }
            if (f == "images") {
              this.images = b.data.imgs;
            }
            if (f == "bots") this.bots = b.data.bots; //preload for full-page
            if (f == "results") {
              //this.results = b.data;
            }
          });
          // this.checkIfAllCallsAreCompleted();
          // console.log("PROJJ!", proj, proj.id);
        });
      }
    },
    clickSearchBotCategories(c) {
      console.log(c, "CAT filtering...");
    },
    clickImageMosaic(i) {
      /*
      i.sequence = this.images;
      this.clickImage(i);
      */
    },

    clickImage({ bot, id, post, sequence }) {
      /*
      //  alert(id + bot);
      this.selectedImage = post;
      //copy entire object clone
      var ar = sequence || [];
      console.log("SEQEUNCE", ar);
      this.trayImageSequence = [...ar];
      */
    },
    nextImage(oneOrMinusOne) {
      /*
      var currentIndex = this.trayCurrentIndex;
      var newIndex = currentIndex + oneOrMinusOne;
      if (newIndex >= this.trayImageSequence.length) newIndex = 0;
      var selectedImage = this.trayImageSequence[newIndex];
      // this.clickImage({ post: this.selectedImage });
      this.selectedImage = selectedImage;
      */
    },
    clickButton: function ({ v, q }) {
      //  alert(url);
      // window.location.href = url;
      //will either set vertical, or query
      if (!q) q = this.query;
      this.$router.push({ path: "/search", query: { q: q, v: v } });
    },
    clickImageInTeaser({ post }) {
      this.$router.push({ path: "/search", query: { q: this.query, v: "images", i: post.id } });

      //copy current images to sequence
      var ar = this.images || [];
      // moved...
      //this.trayImageSequence = [...ar];
      //this.selectedImage = post;
      console.log("clickImageInTeaser 423", post, ar);
      this.initialSelectedImage = post;
      this.initialTrayImageSequence = [...ar];

      //  alert(post.id);
    },
    adjustButtonVisibility() {
      this.$forceUpdate(); // To ensure Vue recalculates the computed properties on resize
    },
    onResize() {
      this.$forceUpdate(); // To ensure Vue recalculates the computed properties on resize
    },
  },

  async mounted() {
    // Set the query from the route when the component is created
    this.query = this.$route.query.q || "";
    await this.performSearch();
    //this.getAutocomplete(this.query);
    window.addEventListener("resize", this.adjustButtonVisibility);
    this.adjustButtonVisibility(); // To set up initially
    this.onResize(); // To set up initially
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustButtonVisibility);
  },
  watch: {
    // Watch for changes in the route
    "$route.query.q": function (val) {
      // this.queryParam = val;
      this.query = this.$route.query.q || "";
      this.performSearch();
    },
    "$route.query.cat": function (val) {
      // this.queryParam = val;
      // this.query = this.$route.query.q || "";
      this.performSearch();
    },
    "$route.query.v": function (val) {
      // this.queryParam = val;
      this.vertical = this.$route.query.v || "";
      this.performSearch();
    },
  },

  computed: {
    vertical() {
      return this.$route.query.v || "all";
    },

    botCategories() {
      var s = this.query;
      var currentCat = this.$route.query.cat;
      var cc = botCategoriesData.map((c) => {
        var base = "/search?v=bots&q=" + s;
        if (!s && c.value == "all") {
          //  base = "/explore";
        } else {
          //  base = `/explore-bots/${c.value}`;
          base += "&cat=" + c.value;
        }
        // if (s) base += `/${s}`;
        c.to = base;

        //set active
        c.active = currentCat == c.value;
        // if not cat, and is 'all, set active.
        if (!currentCat && c.value == "all") c.active = true;

        //counts

        if (c.value == "all") {
          c.count = this.bots.length;
        } else {
          c.count = this.bots.filter((b) => b.category == c.value).length;
        }

        return c;
      });
      return cc;
    },
    loadingButtons() {
      var h = this.loadingRelatedQueries && this.vertical == "all";
      return Boolean(h);
    },
    buttonsShownThatFits() {
      const all = this.buttonsShown;
      const container = this.$refs.buttonsContainer;

      // NO WORK crazy fix to hide partial btns on scroll...
      return all;
      if (!container) return []; // Ensure the reference is available
      console.log(3);
      const max = container.clientWidth;
      let usedWidth = 0;
      const visibleButtons = [];

      for (let i = 0; i < all.length; i++) {
        const button = all[i];
        const testEl = document.createElement("button");

        testEl.className = "button is-rounded regular";
        testEl.innerText = button.text;
        document.body.appendChild(testEl);

        const buttonWidth = testEl.clientWidth + 5; // Adjust 5px if you have a different margin

        document.body.removeChild(testEl);

        if (usedWidth + buttonWidth <= max) {
          usedWidth += buttonWidth;
          visibleButtons.push(button);
        } else {
          break;
        }
      }
      return visibleButtons;
      /**/
    },
    buttonsShown() {
      if (this.vertical == "all") {
        var vertis = PRODUCT_BUTTONS.filter((b) => {
          //return all execpt a ll
          return b.id != "all";
        });
        vertis = vertis.map((p) => {
          p.active = false; //reset state
          return p;
        });
        // append related queries at the begining. change prop names using map.
        var btRelated = this.relatedQueries.map((b) => {
          return {
            //  id: b.q,
            label: b.label,
            q: b.q,

            //    baseUrl: "https://www.google.com/search?q=" + b.q,
          };
        });
        vertis.unshift(...btRelated);
        return vertis;
      } else {
        //it's a vertical
        var currentVertical = PRODUCT_BUTTONS.find((b) => {
          //return all execpt all
          return b.id == this.vertical;
        });
        if (!currentVertical) return [];

        console.log("currentVertical", currentVertical);
        //get related buttons...

        console.log("currentVertical.verticalsBtns,,,, ", currentVertical, currentVertical.verticalsBtns);
        let prods = [];
        if (currentVertical.verticalsBtns) {
          currentVertical.verticalsBtns.forEach((b) => {
            // fint by id
            var bbb = PRODUCT_BUTTONS.find((p) => {
              console.log("p.id", p.id, b);
              return p.id == b;
            });
            prods.push(bbb);
          });
        } else {
          alert("missing verticalsBtns config in searchVerticals.js");
        }

        console.log("prods", prods, currentVertical.verticalsBtns);
        //remove empty ones
        prods = prods.filter((p) => {
          return p;
        });

        prods = prods.map((p) => {
          p.active = p.id == this.vertical;
          return p;
        });

        var allBt = PRODUCT_BUTTONS.find((p) => {
          return p.id == "all";
        });
        prods.unshift(allBt); //append at the begining

        return prods;
      }

      var tags = "Cats Dogs".split(" ");
    },
  },
};
</script>

<style scoped>
.search-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--bg-color-alpha);
  backdrop-filter: blur(5px); /* frost fx */
  padding-bottom: 2px;
  border-bottom: 1px solid #d6dec4;
}
form {
  display: flex;
  justify-content: left;
  align-items: left;
  gap: 10px;
  margin-bottom: 20px;
}

.results-container {
  margin-top: 20px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.product-buttons {
  display: block;

  transition: transform 0.3s ease;
  transition: transform 0.3s ease, max-height 0.3s ease-in-out, all 0.3s ease;
  margin-left: 30px; /*to match search input without it loupe   */
  transform-origin: top left;
  margin-bottom: 8px;
  margin-top: 18px;

  overflow: hidden; /* To ensure content doesn't spill out during the transition */
  max-height: 50px; /* Assuming a height for the button, you should adjust this value */
  max-height: 45px;

  display: flex; /* Use flexbox to line up child items in a row */
  overflow-x: auto; /* Allow horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scrolling */
  white-space: nowrap; /* Prevent content from wrapping to the next line */
  width: 100%; /* Take the full width of the parent, adjust as needed */
  scrollbar-width: thin; /* Optional: For a thinner scrollbar in Firefox */

  overflow: hidden; /* This will hide any buttons that overflow the container */
  white-space: nowrap; /* Ensure content inside doesn't wrap to a new line */
  display: flex; /* Make the container a flex container */
  align-items: center; /* Center the buttons vertically */
  justify-content: start; /* Align buttons to the start of the container */
}
.product-buttons::after {
  content: ""; /* Creates a pseudo-element */
  flex-grow: 1; /* It will grow and consume any remaining space, pushing buttons out of view if needed */
  min-width: 5px; /* The width of the space you want between the last visible button and the container's edge */
}

#app.scrolled .product-buttons {
  /* shrik */
  transform: scale(0);
  max-height: 0; /* collapse the button */
  margin-top: 8px;
}
.product-buttons .button {
  margin-right: 5px;
  font-size: 15px;
  font-weight: 400;
  padding: 0px 17px;
  color: #202020;
}
.product-buttons .button.borderless {
  border: none;
  background: transparent;
  color: #202020;
}
.product-buttons .button.borderless:hover {
  background: white;
  color: #202020;
}

.sticky {
  position: sticky;
  top: 0;
}
.spacer-explore-top {
  height: 100px;
}
.cat-buttons {
  display: block;
  flex-direction: column;
}
.cat-buttons .button {
  display: flex;
  margin-bottom: 10px;
  align-content: stretch;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 5px 28px;
  width: -webkit-fill-available;
}

.cat-buttons .le {
  display: inherit;
}
.cat-buttons .icon {
  padding-right: 10px;
}
.cat-buttons .count {
  opacity: 0.5;
  font-size: 13px;
  margin-left: 10px;
}
</style>
