<template>
  <div class="line-bt" style="text-align: center">
    <hr style="margin-bottom: -33px; margin-top: 30px" />
    <b-button
      @click="click"
      class="view-all-button"
      rounded
      type="is-small is-warning is-outlinedNO"
      style=""
      icon-pack="fal"
      icon-right="arrow-right"
    >
      {{ label }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: "SearchHeading",
  props: {
    label: {
      type: String,
      default: "View all",
    },
    to: {
      type: String,
      default: "",
    },
  },
  methods: {
    click() {
      //emit
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
hr,
.view-all-button {
  /* Your button styling */

  font-size: 14px;
  font-weight: 400;
}

.view-all-button {
  margin-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  background: #e5e8de;
  padding: 2px 60px;
}

.view-all-button:hover {
  /* Your button styling */
  background: #d4dac5;
}
</style>
