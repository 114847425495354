<template>
  <section class="faq">
    <searchHeading title="People also ask" icon="" />
    <div v-for="(faq, index) in faqs" :key="index">
      <b-collapse :open="false" :aria-id="'faqContent' + index" animation="slide">
        <template #trigger="props">
          <b-button
            :label="faq.q"
            type=""
            :aria-controls="'faqContent' + index"
            :aria-expanded="props.open"
            icon-right="angle-down"
            expanded
          />
        </template>
        <div class="notification">
          <div class="content">
            <h3 class="title is-5">
              {{ faq.shortResponse }}
            </h3>
            <p>
              {{ faq.longResponse }}
            </p>
          </div>
        </div>
      </b-collapse>
    </div>
  </section>
</template>

<script>
import searchHeading from "@/components/search/searchHeading.vue";
export default {
  components: {
    searchHeading,
  },
  props: {
    faqs: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped>
.faq {
  margin-top: 20px;
  text-align: left;
  max-width: 650px;
}
.faq .button {
  width: 100%;
  justify-content: space-between;
  display: flex;
  border-radius: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  background: transparent;
}
.faq .button:focus {
  outline: none;
  box-shadow: none;
  border-color: inherit;
}
.faq .notification {
  background: transparent;
}
.faq h3 {
  margin-bottom: 0.4em;
}
</style>
