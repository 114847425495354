<template>
  <div v-if="visible && data && id" :class="'block block-' + id">
    <!--  MAIN SEARCH RESULTS BLOCKS ALL HERE... -->

    <!-- If id is 'image' -->
    <div v-if="id === 'images'" class="imgTeaser" style="width: 100%; max-width: 800px">
      <searchImageTeaser
        :images="data.imgs?.slice(0, 33)"
        @viewAllImages="viewAllImages"
        @clickImageInTeaser="clickImageInTeaser"
        :scale="70"
        style="width: 100%"
        :menu="menu"
      />
    </div>

    <template v-if="id === 'generate-image'">
      <searchHeading @click="viewAllImagine" title="Imagine" icon="sparkles" :menu="menu" />
      <!-- Create images -->
      <searchImagine ref="searchImagine" :templates="data.templates" :noDescription="false" :pageSize="9999" />

      <viewMoreButton @click="viewAllImagine" label="Create more" />
    </template>

    <div v-if="id === 'faq'">
      <searchFaq :faqs="data.faqs" v-if="data.faqs.length" />
    </div>

    <template v-if="id === 'calculator'">
      <calculator :expressionProp="data.expression" v-if="data.expression" />
    </template>

    <template v-if="id === 'bots'">
      <searchHeading @click="viewAllBots" title="Bots" icon="user-friends" />
      <BotList :nowrap="true" :bots="data.bots" :noDescription="false" :pageSize="100" />
      <viewMoreButton @click="viewAllBots" label="View more" />
    </template>

    <template v-if="id === 'suicide'">
      <div class="notification is-warning">
        <h1 class="title is-3 has-text-centeredNO">You're not alone. Help is available</h1>
        <p class="subtitle is-6 has-text-centeredNO">
          It's important to get help if you or someone you know is going through a crisis or thinking about suicide.
        </p>
        <div class=" ">
          <a
            class="button is-dark is-cend"
            :href="data?.link || 'https://findahelpline.com'"
            target="_blank"
            rel="noopener noreferrer"
            >Find a Helpline</a
          >
        </div>

        <!-- Additional content like buttons or contact information can be added below -->
        <!-- Example Button -->
        <!-- <a class="button is-primary is-centered" href="#">Get Help Now</a> -->
      </div>
    </template>

    <!-- Other ids and components can be added here with v-else-if or v-else clauses -->
  </div>
</template>

<script>
import searchHeading from "@/components/search/searchHeading.vue";
import viewMoreButton from "@/components/search/viewMoreButton.vue";
import searchImageTeaser from "@/components/search/searchImageTeaser.vue";
import searchFaq from "@/components/search/searchFaq.vue";
import calculator from "@/components/search/calculator.vue";
import searchImagine from "@/components/search/searchImagine.vue";
export default {
  components: {
    searchHeading,
    searchImageTeaser,
    searchFaq,
    calculator,
    viewMoreButton,
    searchImagine,
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => null,
    },
    id: {
      type: String,
      default: "",
    },
  },

  methods: {
    viewAllImages(event) {
      this.$emit("viewAllImages", event);
    },
    viewAllBots(event) {
      this.$emit("viewAllBots", event);
    },
    viewAllImagine(event) {
      //alert(2222222);
      // this.$emit("viewAllImagine", event);
      // retrive currently selected template in searchImagine using ref
      var blue = this.$refs.searchImagine.selectedTemplate;
      let u = "/images/new";

      if (blue) u = `/images/${blue.bot}?template=${blue.id}`;
      this.$router.push(u);
    },
    clickImageInTeaser(event) {
      this.$emit("clickImageInTeaser", event);
    },
    // ... other methods for handling events can be added here
  },
  computed: {
    menu() {
      return [
        {
          label: "View all",
          action: this.viewAllImages,
        },
        {
          t: "View post",
          icon: "far fa-eye",
          to: "/explore-bots/1",
          //hidden: this.isDetail,
        },
      ];
    },
  },
};
</script>

<style scoped>
/* Your styles can go here */
.block {
  margin-top: 50px;
}
</style>
