<template>
  <div class="search-heading">
    <h1 class="title is-5">
      <span class="t" @click="clickTitle">
        <!-- Display icon if it exists -->
        <b-icon v-if="icon" :icon="icon" pack="far" style="" class="ii" />
        {{ title }}
      </span>
      <DotDrop :horizontal="false" :items="menu" position="is-bottom-right" />
    </h1>

    <!-- Slot to allow for customizable menu/content -->
    <slot></slot>
  </div>
</template>

<script>
import DotDrop from "@/components/e/DotDrop.vue";

export default {
  name: "SearchHeading",
  components: {
    DotDrop,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },

    action: {
      type: Function,
      default: () => {},
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    clickTitle() {
      //emit
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.search-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-heading h1.title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 400;
}
/*
h1 > span {
  margin-right: 10px;
}
*/
.ii {
  font-size: 20px;
  color: #aaa;
  margin-right: 10px;
}

h1 span {
  align-items: center;
  display: flex;
}

h1 .t:hover .ii {
  color: #aaa;
  color: #000;
}

h1 .t:hover {
  color: blue;
  cursor: pointer;
}
</style>
