<template>
  <div>
    <searchHeading @click="viewAllImages" title="Images" icon="image" :menu="menu" />
    <div class="image-swim-lane" v-if="images && images.length">
      <div class="image-and-bt" ref=" ">
        <div class="lane-container">
          <div class="image-container" ref="imageContainer">
            <img
              v-for="(i, index) in images"
              @click="clickImageInTeaser(i)"
              :key="index"
              :src="i.featuredImage?.url600"
              class="image-item"
            />
          </div>
        </div>
        <div class="more-button is-hidden-touch" @click="scrollRight">
          <b-button rounded type="is-nom is-outlinedNO" style="margin-top: 10px" icon-right="chevron-right"> </b-button>
        </div>
      </div>
      <viewMoreButton @click="viewAllImages" label="View all" />
      <!-- 
      <div class="line-bt" style="text-align: center">
        <hr style="margin-bottom: -33px; margin-top: 30px" />
        <b-button
          @click="viewAllImages"
          rounded
          type="is-small is-warning is-outlinedNO"
          style="margin-top: 10px; padding-left: 50px; padding-right: 50px"
          icon-right="arrow-right"
        >
          View all
        </b-button>

      </div>-->
      <!--
    <button class="view-all-button" @click="viewAll">View All</button>
      -->
    </div>
  </div>
</template>

<script>
import searchHeading from "@/components/search/searchHeading.vue";
import viewMoreButton from "@/components/search/viewMoreButton.vue";
export default {
  components: {
    searchHeading,
    viewMoreButton,
  },
  props: {
    q: {
      type: String,
      default: "",
    },
    scale: {
      type: Number,
      default: 150,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: function () {
        return [];
      },
    },
    menu: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  data() {
    return {
      // images: [], // You should populate this array with image URLs.
    };
  },
  methods: {
    scrollRight() {
      let container = this.$refs.imageContainer;
      let maxScrollLeft = container.scrollWidth - container.clientWidth;
      if (container.scrollLeft < maxScrollLeft) {
        container.scrollBy({
          top: 0,
          left: 600, // You can adjust the scroll distance as per your requirement.
          behavior: "smooth",
        });
      } else {
        this.viewAll();
      }
    },
    clickImageInTeaser(post) {
      this.$emit("clickImageInTeaser", { post });

      // Handle click image logic here
      // It could be opening a modal or navigating to a new route.
    },
    viewAllImages() {
      this.$emit("viewAllImages");

      // Handle view all logic here
      // It could be opening a modal or navigating to a new route.
    },
  },
};
</script>

<style scoped>
.image-swim-lane {
  width: 100%;
}
.image-and-bt {
  position: relative;
  display: flex; /* add this */
}
.lane-container {
  display: flex;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}

.image-container {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-width: none; /* For Firefox */
}

.image-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}

.image-item {
  height: 160px; /* row h */
  flex: 0 0 auto;

  margin-right: 6px;
  cursor: pointer;
}

.more-button {
  /* Rest of your button styling */
  position: absolute;

  right: -26px;
  top: 39px;
}
</style>
